
import { defineComponent } from "vue";
import ClientLogo01 from "@/components/atoms/icon/ClientLogo/ClientLogo01.vue";
import ClientLogo02 from "@/components/atoms/icon/ClientLogo/ClientLogo02.vue";
import ClientLogo03 from "@/components/atoms/icon/ClientLogo/ClientLogo03.vue";
import ClientLogo04 from "@/components/atoms/icon/ClientLogo/ClientLogo04.vue";
import ClientLogo05 from "@/components/atoms/icon/ClientLogo/ClientLogo05.vue";

export default defineComponent({
  components: {
    ClientLogo01,
    ClientLogo02,
    ClientLogo03,
    ClientLogo04,
    ClientLogo05
  }
});
