
import "@/utils/scrollPatch";
import { onUnmounted, defineComponent, onMounted } from "vue";
import { trackerSetAction } from "@/utils/vertebraSdk";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import MainTopIntro from "@/components/templates/MainTopIntro/MainTopIntro.vue";
import MainServiceIntro from "@/components/templates/MainServiceIntro/MainServiceIntro.vue";
import MainServiceCase from "@/components/templates/MainServiceCase/MainServiceCase.vue";
import MainAdvantage from "@/components/templates/MainAdvantage/MainAdvantage.vue";
import MainClient from "@/components/templates/MainClient/MainClient.vue";
import SubBannerSignup from "@/components/templates/SubBannerSignup/SubBannerSignup.vue";
export default defineComponent({
  name: "Main",
  components: {
    MainTopIntro,
    MainServiceIntro,
    MainServiceCase,
    MainAdvantage,
    MainClient,
    SubBannerSignup,
  },
  setup() {
    useHead({
      link: [{ rel: "canonical", href: process.env.VUE_APP_BASE_URL }]
    });
    const store = useStore();
    const setFeatureState = (state: boolean) => {
      store.dispatch("pageCheck/featureCheck", state);
    };
    setFeatureState(true);
    onMounted(() => {
      trackerSetAction("첫 페이지");
    });
    onUnmounted(() => setFeatureState(false));
    return {
      setFeatureState
    };
  }
});
