import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainTopIntro = _resolveComponent("MainTopIntro")!
  const _component_MainServiceIntro = _resolveComponent("MainServiceIntro")!
  const _component_MainServiceCase = _resolveComponent("MainServiceCase")!
  const _component_MainAdvantage = _resolveComponent("MainAdvantage")!
  const _component_MainClient = _resolveComponent("MainClient")!
  const _component_SubBannerSignup = _resolveComponent("SubBannerSignup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainTopIntro),
    _createVNode(_component_MainServiceIntro),
    _createVNode(_component_MainServiceCase),
    _createVNode(_component_MainAdvantage),
    _createVNode(_component_MainClient),
    _createVNode(_component_SubBannerSignup)
  ], 64))
}