
import { defineComponent } from "vue";
import IntroIcon01 from "@/components/atoms/icon/IntroIcon/IntroIcon01.vue";
import IntroIcon02 from "@/components/atoms/icon/IntroIcon/IntroIcon02.vue";
import IntroIcon03 from "@/components/atoms/icon/IntroIcon/IntroIcon03.vue";

export default defineComponent({
  components: {
    IntroIcon01,
    IntroIcon02,
    IntroIcon03
  }
});
