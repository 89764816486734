
import { defineComponent } from "vue";
import AdvantageIcon01 from "@/components/atoms/icon/AdvantageIcon/AdvantageIcon01.vue";
import AdvantageIcon02 from "@/components/atoms/icon/AdvantageIcon/AdvantageIcon02.vue";
import AdvantageIcon03 from "@/components/atoms/icon/AdvantageIcon/AdvantageIcon03.vue";
import AdvantageIcon04 from "@/components/atoms/icon/AdvantageIcon/AdvantageIcon04.vue";

export default defineComponent({
  components: {
    AdvantageIcon01,
    AdvantageIcon02,
    AdvantageIcon03,
    AdvantageIcon04
  }
});
