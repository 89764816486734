
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";
import CaseIcon01 from "@/components/atoms/icon/CaseIcon/CaseIcon01.vue";
import CaseIcon02 from "@/components/atoms/icon/CaseIcon/CaseIcon02.vue";
import CaseIcon03 from "@/components/atoms/icon/CaseIcon/CaseIcon03.vue";
import CaseIcon04 from "@/components/atoms/icon/CaseIcon/CaseIcon04.vue";
import CaseIcon05 from "@/components/atoms/icon/CaseIcon/CaseIcon05.vue";

export default defineComponent({
  components: {
    CaseIcon01,
    CaseIcon02,
    CaseIcon03,
    CaseIcon04,
    CaseIcon05
  },
  setup() {
    const contentsWrapRef = ref() as Ref<HTMLElement>;
    const scrollWrapRef = ref() as Ref<HTMLElement>;
    const stickyRef = ref() as Ref<HTMLElement>;

    const calcDynamicHeight = (el: HTMLElement) => {
      const vw = window.innerWidth;
      const vh = window.innerHeight;
      const objectWidth = el.scrollWidth;
      return objectWidth - vw + vh + 10;
    };

    const transformHorizontal = () => {
      scrollWrapRef.value.style.transform = `translateX(-${stickyRef?.value
        ?.offsetTop - 170}px)`;
    };

    const contentWrapResize = () => {
      contentsWrapRef.value.style.height = `${calcDynamicHeight(
        scrollWrapRef?.value
      )}px`;
    };

    onMounted(() => {
      contentWrapResize();
      window.addEventListener("scroll", transformHorizontal);
      window.addEventListener("resize", contentWrapResize);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", transformHorizontal);
      window.removeEventListener("resize", contentWrapResize);
    });

    return {
      contentsWrapRef,
      scrollWrapRef,
      stickyRef
    };
  }
});
